import request from '@/utils/request'
import qs from 'qs'

// 专题到二级专题
export function getSpecList() {
  return request({
    url: '/product/spec',
    method: 'post'
  })
}

// 文章类型，需传入pid=5
export function getArtClaList(data) {
  return request({
    url: '/article/articleClassAll',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 二级专题下分类下的文章
export function getSpecClaList(data) {
  return request({
    url: '/art/specCla',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 二级专题下文章
export function getSpecArticleList(data) {
  return request({
    url: '/art/spec',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 具体文章
export function getArticleDetail(data) {
  return request({
    url: '/art/detail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取最新文章
export function getNewestArticle(data) {
  return request({
    url: '/index/article',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 根据二级专题查询相关课程和相关资讯
export function getComboBySpec(data) {
  return request({
    url: '/product/getComboBySpec',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取资讯广告
export function getAdvert(data) {
  return request({
    url: '/Advert/getByPid',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 二级专题下的考试时间进度
export function getSpecTimeLine(data) {
  return request({
    url: '/specTimeLine',
    method: 'post',
    data: qs.stringify(data)
  })
}
