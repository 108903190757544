<template>
  <div>
    <nav-bar></nav-bar>
    <tool-box></tool-box>

    <!-- 顶部菜单 -->
    <el-affix>
      <el-menu mode="horizontal" menu-trigger="hover" collapse-transition="false">
        <div class="container">
          <el-row class="new-home-top">
            <el-col :span="4">
              <span style="font-size: 20px;cursor: pointer;" @click="this.$router.push('/news')">
                {{ currentSpecial.title }}
                <svg-icon iconClass="jiantouarrow486"></svg-icon>
              </span>
            </el-col>
            <el-col :span="4" v-for="(type,index) in typeList" :key="type.id">
              <span class="el-dropdown-link" @click="goNewsList(type)">
                {{ type.title }}
                <span style="float: right;" v-if="index < typeList.length - 1">|</span>
              </span>
            </el-col>
          </el-row>
        </div>
      </el-menu>
    </el-affix>
    
    <div class="container center">
      <el-row :gutter="20">
        <el-col :span="16">
          <div class="information">
            <div class="information-title">{{ newsDetail.article_title  }}</div>
            <div class="information-time">发布时间：{{ newsDetail.create_time }}</div>
            <div class="information-content" v-html="newsDetail.article_content"></div>
            <div class="information-statement">免责说明：因考试政策、内容不断变化与调整，以上内容均为搜集编辑整理，仅供参考。如涉及版权问题，请联系本站管理员予以更改或删除。</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="relevant-class">
            <div class="relevant-class-title">相关课程</div>
            <div class="relevant-class-item" v-for="item in classList" :key="item.id">
              <combo-class-item :item='item'></combo-class-item>
            </div>
          </div>
          <div class="relevant-news">
            <div class="relevant-news-title">相关文章</div>
            <div class="relevant-news-item" :title="item.article_title"
              v-for="item in newsList" :key="item.id" @click="goDetail(item)">
              {{ item.article_title }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script>
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import SvgIcon from '@/components/svgicon'
import ComboClassItem from './components/ComboClassItem'
import { getArtClaList, getArticleDetail, getComboBySpec } from '@/api/news'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
    SvgIcon,
    ComboClassItem
  },
	data() {
		return {
      // 文章类型
      typeList: [],
      // 当前专题
      currentSpecial: {},
      // 文章详情
      newsDetail: {},
      // 相关班级
      classList: [],
      // 相关新闻文章
      newsList: [],

		}
	},
  created() {
    getArtClaList({pid: 5}).then(res => {
      if (res.code == 1) {
        this.typeList = res.data
      }
    })
    getArticleDetail({id: this.$route.params.id}).then(res => {
      if (res.code == 1) {
        this.newsDetail = res.data
        this.getRelevantList(res.data.spec_id)
      }
    })
  },
	methods: {
    // 根据二级专题获取相关班级、资讯文章
    getRelevantList(specId) {
      getComboBySpec({spec_id: specId}).then(res => {
        if (res.code == 1) {
          this.currentSpecial = res.data.info
          this.classList = res.data.comboclass
          // 获取相关资讯
          res.data.art.forEach(item => {
            if (item.id == this.newsDetail.pid) {
              this.newsList = item.article.slice(0, 10)
            }
          })
        }
      })
    },
    // 打开资讯文章详情
    goDetail(news) {
      window.open('/newsdetail/' + news.article_id)
    },
    // 跳转到列表页
    goNewsList(type) {
      this.$router.push('/newslist/' + this.currentSpecial.id + '/' + type.id)
    },
    
	},
}
</script>

<style scoped lang="scss">
.center {
  margin: 50px auto;
}
// 顶部菜单 begin
.el-menu {
  background-color: $theme-color;
}
.new-home-top {
  color: #fff;
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  text-align: center;
}
.new-home-top-title {
  font-size: 22px;
}
.new-home-top:not(.no-border) {
  border-right: 2px solid #fff;
}
.new-home-top:not(.no-border) {
  border-right: 0;
}
.el-dropdown-link {
  display: inline-block;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  width: 200px;
  line-height: 50px;
}
// 顶部菜单 end

// 内容 begin
.information {
  background-color: $background-color;
  padding: 30px;

  .information-title {
    font-size: 24px;
    letter-spacing: 1px;
  }
  .information-time {
    color: #999;
    font-size: 12px;
    margin-top: 8px;
  }
  .information-content {
    margin: 30px 0;
  }
  .information-statement {
    color: #999;
    line-height: 28px;
  }
}
// 内容 end

// 相关班级 begin
.relevant-class {
  background-color: $background-color;
  padding: 10px;

  .relevant-class-title {
    font-size: 20px;
    font-weight: 600;
  }
  .relevant-class-item {
    margin: 20px;
    .item-img {
      width: 120px;
    }
    .item-name {
      padding-left: 10px;
    }
  }
  .relevant-class-item:hover {
    color: $theme-color;  
  }
}
// 相关班级 end

// 相关文章 begin
.relevant-news {
  background-color: $background-color;
  padding: 10px;
  margin-top: 30px;

  .relevant-news-title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #c5c1c1;
    padding: 10px 0;
  }
  .relevant-news-item {
    color: #666;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 10px 0;
    cursor: pointer;
  }
  .relevant-news-item:hover {
    color: $theme-color;
  }
}
// 相关文章 end
</style>